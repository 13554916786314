footer{
height: auto;
  background-color: #6B2737;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 200px;
}



#smallLogo {
max-block-size: 120px;
padding-top: 20px !important;
margin-bottom: 20px;
}

/* #092327 dark green
#E2FCEF light green
#7D8491 silver
#6B2737 red 
#DD614A orange  */