#introParagraph {
    margin-top: 55px;
    color: white;
    text-align: center;
    border: 3px solid white;
    background-color: #E2FCEF;
    border-radius: 10px;
    margin-right: 20px;
    margin-left: 20px;
    color: black;
    font-weight: normal;
    font-size: larger;
    padding: 35px;
    margin-bottom: 25px;
    box-shadow: rgb(100, 37, 37) 0px 20px 30px -10px;
}

#aboutmeHead {
  margin-top: 55px;
}

#chloeAndAirbus {
display: block;
margin-left: auto;
margin-right: auto;
max-height: 500px;
border-radius: 25px;
border: #E2FCEF solid 4px;
margin-top: 55px;
}

#testimonials {
    margin-top: 250px;
}

#caption {
    margin-top: 250px !important;
  }
  
  .legend {
    font-size: 14px !important;
    background-color: #6B2737 !important;
    border: none !important;

  }
  
  #projPhoto {
    size: 800px !important;
}
img {
    box-shadow: rgba(#6B2737) 0px 22px 70px 4px;
    max-height: 650px;
    max-width: 650px !important;
    
}


#trainingPics {
  width: 25px;
  height: 600px;
  margin: 55px;
}

#reviewsSection {
margin-top: 80px !important; 
}

h2 {
  font-family: 'Special Elite', cursive;
  color: white;
  font-size: xx-large;
  
  text-align: center;
}

#training {
  height: 300px !important;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  box-shadow: 5px 10px #6B2737 ;
}
/* #092327 dark green
#E2FCEF light green
#7D8491 silver
#6B2737 red 
#DD614A orange  */


	.hero-section__section {
		margin: var(--section-margin) auto;
		min-height: clamp(90vh, 100vw, calc(100vh - var(--navbar-height)));
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		gap: 1rem;
	}
	.hero-section__text {
		justify-self: center;

	}
	.hero-btns__container {
		display: flex;
		align-items: center;
		gap: 1rem;
	}
	.hero-btns__container {
		margin-right: 1rem;
	}
	.hero-section__image {
		display: flex;
	}
	.hero-section__image img {
		width: 100%;
		height: 100%;
		max-height: 80vh;
		object-fit: cover;
		border-radius: var(--theme-shape-radius);
	}
	@media (max-width: 768px) {
		.hero-section__section {
			grid-template-columns: 1fr;
		}
	}
