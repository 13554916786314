.App {
  background-image: url(C:\Users\DSub\Desktop\TheTraveler-sK9\client\src\Styles\airbus.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Barlow', sans-serif;
}

#vanLife {
  max-height: 180px;
  margin-left: 25px;
  border-radius: 100px;
}

h1 {
  margin-top: 50px !important;
  font-size: 65px !important;
  font-family:'Rubik Moonrocks', cursive; ;
  padding: 25px;
  text-align: center !important;
  color: #ff672b;
}
.demo {
  font-family: 'Raleway', sans-serif;
  color:#fff;
    display: block;
    margin: 0 auto;
    padding: 15px 0;
    text-align: center;
}

#content {
  background-image: url("https://i.pinimg.com/564x/3d/74/63/3d74639d40ae75295fd25719ce35b886.jpg");
  background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

